import { create } from 'zustand';
import { persist } from "zustand/middleware";

type TerminalStoreState = {
  mobileTerminals: number;
  staticTerminals: number;
  inalambricoTerminals: number;

  setMobileTerminals: (newMobileTerminals: number) => void;
  setStaticTerminals: (newStaticTerminals: number) => void;
  setInalambricoTerminals: (newInalambricoTerminals: number) => void;

  addMobileTerminals: () => void;
  addStaticTerminals: () => void;
  addInalambricoTerminals: () => void;

  subtractMobileTerminals: () => void;
  subtractStaticTerminals: () => void;
  subtractInalambricoTerminals: () => void;

  resetMobileTerminals: () => void;
  resetStaticTerminals: () => void;
  resetInalambricoTerminals: () => void;
};

const useFlowTerminalStore = create<TerminalStoreState>()(
  persist(
    (set) => ({
      mobileTerminals: 0,
      staticTerminals: 0,
      inalambricoTerminals: 0,

      // Set
      setMobileTerminals: (newMobileTerminals) =>
        set({ mobileTerminals: newMobileTerminals }),
      setStaticTerminals: (newStaticTerminals) =>
        set({ staticTerminals: newStaticTerminals }),
      setInalambricoTerminals: (newInalambricoTerminals) =>
        set({ inalambricoTerminals: newInalambricoTerminals }),

      // Add
      addMobileTerminals: () =>
        set((state) => ({ mobileTerminals: state.mobileTerminals + 1 })),
      addStaticTerminals: () =>
        set((state) => ({ staticTerminals: state.staticTerminals + 1 })),
      addInalambricoTerminals: () =>
        set((state) => ({
          inalambricoTerminals: state.inalambricoTerminals + 1,
        })),

      // Subtract
      subtractMobileTerminals: () =>
        set((state) => ({ mobileTerminals: state.mobileTerminals - 1 })),
      subtractStaticTerminals: () =>
        set((state) => ({ staticTerminals: state.staticTerminals - 1 })),
      subtractInalambricoTerminals: () =>
        set((state) => ({
          inalambricoTerminals: state.inalambricoTerminals - 1,
        })),

      // reset
      resetMobileTerminals: () => set({ mobileTerminals: 0 }),
      resetStaticTerminals: () => set({ staticTerminals: 0 }),
      resetInalambricoTerminals: () => set({ inalambricoTerminals: 0 }),
    }),
    {
      name: "flowTerminalStore",
    }
  )
);

// Use Immer to set limit 0 to terminals
// https://betterprogramming.pub/react-state-management-with-zustand-b816472b4be7

export default useFlowTerminalStore;
