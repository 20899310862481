import * as yup from 'yup';

const prefencesSchema = yup.object().shape({
  currentAcceptingCards: yup.boolean().required(),
  companyRFCName: yup
    .string()
    .min(3, 'Por favor pon el nombre de tu empresa.')
    .required('Por favor pon el nombre de tu empresa.'),
  sector: yup
    .number()
    .min(1, 'Por favor elige un sector')
    .max(100, 'Tiene que ser menos que 100')
    .positive('Por favor elige un sector')
    .required('Por favor elige un sector'),
  mobileTerminals: yup
    .number()
    .test(
      'oneOfRequired',
      'Tienes que eligir minimo una terminal.',
      function (item) {
        return (
          this.parent.mobileTerminals ||
          this.parent.staticTerminals ||
          this.parent.inalambricoTerminals
        );
      }
    ),
  staticTerminals: yup
    .number()
    .test(
      'oneOfRequired',
      'Tienes que eligir minimo una terminal.',
      function (item) {
        return (
          this.parent.mobileTerminals ||
          this.parent.staticTerminals ||
          this.parent.inalambricoTerminals
        );
      }
    ),
  inalambricoTerminals: yup
    .number()
    .test(
      'oneOfRequired',
      'Tienes que eligir minimo una terminal.',
      function (item) {
        return (
          this.parent.mobileTerminals ||
          this.parent.staticTerminals ||
          this.parent.inalambricoTerminals
        );
      }
    ),

  email: yup
    .string()
    .email('Este correo electrónico es invalido.')
    .required(
      'Por favor pon tu correo electrónico para que te mandamos la oferta y actualicaciones de tu selección.'
    ),
  phone: yup
    .string()
    .required(
      'Por favor pon el número telefónico para seguimento y consultas sobre nuestros ofertas de pago.'
    )
    .matches(/^[0-9]+$/, 'El número telefónico solo puede contener dígitos.')
    .min(10, 'El número debe ser de 10 dígitos.')
    .max(10, 'El número debe ser de 10 dígitos.'),
});

export default prefencesSchema;
