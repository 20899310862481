import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type CompanyDataStore = {
  currentAcceptingCards: boolean;
  companyRFCName: string;
  sector: number;
  debitDistribution: number;
  creditDistribution: number;
  contractStart: string;
  monthlyCardTurnover: number | null;
  averageTransaction: number | null;
  lastBill: number | null;

  switchCurrentAcceptingCards: () => void;
  setCurrentAcceptingCards: (newCurrentAcceptingCards: boolean) => void;
  setCompanyRFCName: (newCompanyRFCName: string) => void;
  setSector: (newSector: number) => void;
  setMonthlyCardTurnover: (newMonthlyCardTurnover: number) => void;
  setAverageTransaction: (newAverageTransaction: number) => void;
  setLastBill: (newLastBill: number) => void;
  setDebitDistribution: (newDebitDistribution: number) => void;
  setCreditDistribution: (newCreditDistribution: number) => void;
  setContractStart: (newContractStart: string) => void;
};

const useFlowCompanyDataStore = create<CompanyDataStore>()(
  persist(
    (set) => ({
      currentAcceptingCards: true,
      companyRFCName: '',
      sector: 0,
      debitDistribution: 85,
      creditDistribution: 15,
      contractStart: 'Fecha para empezar el contrato',
      monthlyCardTurnover: null,
      averageTransaction: null,
      lastBill: null,
      // currentProvider: 0,

      switchCurrentAcceptingCards: () =>
        set((state) => ({
          currentAcceptingCards: !state.currentAcceptingCards,
        })),
      setCurrentAcceptingCards: (newCurrentAcceptingCards) =>
        set({ currentAcceptingCards: newCurrentAcceptingCards }),
      setCompanyRFCName: (newCompanyRFCName) =>
        set({ companyRFCName: newCompanyRFCName }),
      setSector: (newSector) => set({ sector: newSector }),
      setMonthlyCardTurnover: (newMonthlyCardTurnover) =>
        set({ monthlyCardTurnover: newMonthlyCardTurnover }),
      setAverageTransaction: (newAverageTransaction) =>
        set({ averageTransaction: newAverageTransaction }),
      setLastBill: (newLastBill) => set({ lastBill: newLastBill }),
      setDebitDistribution: (newDebitDistribution) =>
        set({
          debitDistribution: newDebitDistribution,
          creditDistribution: 100 - newDebitDistribution,
        }),
      setCreditDistribution: (newCreditDistribution) =>
        set({
          creditDistribution: newCreditDistribution,
          debitDistribution: 100 - newCreditDistribution,
        }),
      // setCurrentProvider: (newCurrentProvider: number) =>
      //   set({ currentProvider: newCurrentProvider }),

      setContractStart: (newContractStart) =>
        set({ contractStart: newContractStart }),
    }),
    {
      name: 'Flow-companyData-store',
    }
  )
);

export default useFlowCompanyDataStore;
