import * as nprogress from 'nprogress';
import axios from 'axios';
import useFlowProviderStore from '../../FlowStore/FlowProviderStore';

import 'nprogress/nprogress.css';

const apiURL = process.env.GATSBY_API_URL;

async function getProviderDataAPI(
  currentAcceptingCards: boolean,
  sector: number,
  mobileTerminals: number,
  staticTerminals: number,
  inalambricoTerminals: number,
  email: string
) {
  nprogress.start();

  // Import states 
  const { setProviderData } = useFlowProviderStore.getState();

  const calculo_data = {
    // Step 1
    email, // To find the user.
    currentAcceptingCards,
    sector,
    monthlyCardTurnover: 1234, // monthlyCardTurnover, // REMOVE FROM BACKEND?
    averageTransaction: 123, // averageTransaction, // REMOVE FROM BACKEND?
    debitDistribution: 0.5, // debitDistribution: debitDist, // REMOVE FROM BACKEND?
    creditDistribution: 0.5, // creditDistribution: creditDist, // REMOVE FROM BACKEND?
    // Choose Terminals
    mobileTerminals,
    staticTerminals,
    wifiTerminals: 0, // REMOVE FROM BACKEND
    gprsTerminals: inalambricoTerminals, // REMOVE FROM BACKEND, for now only send gprsTerminals
  };

  const urlCalculo = `${apiURL}/calculo`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  let success = false;
  try {
    const res = await axios.post(urlCalculo, JSON.stringify(calculo_data));
    // console.log(res.data);
    setProviderData(res.data);
    success = true;
  } catch (error) {
    console.log(error);
  }

  nprogress.done();
  return success;
}

export default getProviderDataAPI;
